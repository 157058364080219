import { apiSlice } from "app/api/apiSlice"

export const BankingApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBankingLeads: builder.query({
            query: (pagination) => `/api/v1/banking/leads?page=${pagination.page}&per_page=15`
        }), 
        getBankingLeadsById: builder.query({
            query: (id) => `/api/v1/crm/leads/${id}`
        }),
        addNewBankingLead: builder.mutation({
            query: (payload) => ({ url: "/api/v1/banking/leads", method: 'POST', body: payload })

        }),
        updateBankingLeadById:builder.mutation({
            query:({id,payload})=>({url:`/api/v1/banking/leads/${id}`,method:`PUT`,body:payload })

        }),
        getBankingApplications: builder.query({
            query: (pagination) => `/api/v1/banking/applications?page=${pagination.page}&per_page=10`
        }), 
         getBankingApplicationsById: builder.query({
            query: (id) => `/api/v1/banking/application/${id}`
        }),
        getoccupationDropdown:builder.query({
            query: (id) => `/api/v1/master/dropdowns`

        }),
        updateConsent:builder.mutation({
            query:({id,payload})=>({url : "/api/v1/banking/update-consent", method: "POST", body:payload})


        }),
        addBankingApplication: builder.mutation({
            query: (payload) => ({ url: "/api/v1/banking/application", method: 'POST', body: payload })

        }),
        getBankingApplicationDetailsId: builder.query({
            query: (id) => `/api/v1/banking/application-data/${id}`
        }),
        createApplicant: builder.mutation({
            query: (payload) => ({ url: "/api/v1/banking/applicants", method: 'POST', body: payload })

        }),
        getCrmLeads: builder.query({
            query: ({pagination,searchKey,activeBusinessKey}) => `/api/v1/crm/banking/leads?page=${pagination.page}&per_page=10&q=${searchKey}${activeBusinessKey==='B2C' ? "&type=B2C": activeBusinessKey==='B2B'?'&type=B2B':""}`
        }), 
        getCrmLeadsById: builder.query({
            query: (id) => `/api/v1/crm/leads/${id}`
        }),
        addCrmLead: builder.mutation({
            query: (payload) => ({ url: "/api/v1/crm/leads", method: 'POST', body: payload })

        }),
        updateCrmLeadById:builder.mutation({
            query:({id,payload})=>({url:`/api/v1/crm/leads/${id}`,method:`PUT`,body:payload })

        }),
        updateLeadApplication: builder.mutation({
            query: ({id,payload}) => ({ url: `/api/v1/crm/leads/${id}/convert`, method: 'POST', body: payload })

        }),
        getBankingCrmDocuments:builder.query({
            query: (id) => `/api/v1/banking/documents/${id}`

        }),
        getBankingLoanApplication: builder.query({
            query: ({pagination,activeBusinessKey,searchKey}) => `/api/v1/banking/application-data?page=${pagination.page}&per_page=10&product_type=${activeBusinessKey}&query=${searchKey}`
            // query: ({pagination,searchKey}) => `/api/v1/banking/application-data?page=${pagination.page}&per_page=10&query=${searchKey}`

        }),
        getBankingLeadsList: builder.query({
            query: () => `api/v1/crm/banking/leads`
        }),
        getPmfmeData :builder.query({
            query: ({searchKey}) => `loan-application/loan-users?search=${searchKey}`
        }),
        getPmfmeDatabyId :builder.query({
            query: (id) => `loan-application/loan-users/${id}`
        }),
        PmfmeAprrovalbyId:builder.mutation({
            query:({ApplicantId,approval})=>({url:`/loan-application/action/${ApplicantId}`,method:`PATCH`,body:approval })

        }),

        updateApplicantBanking:builder.mutation({
            query:({id,payload})=>({url:`/api/v1/banking/applicants/${id}`,method:`PUT`,body:payload })

        }),
        updatePmfePersonalById:builder.mutation({
            query:({id,payload})=>({url:`/loan-application/personal-detail/${id}`,method:`PUT`,body:payload })

        }),
        updatePmfeBusinessById:builder.mutation({
            query:({id,payload})=>({url:`/loan-application/pmfme-form/business-detail/${id}`,method:`PUT`,body:payload })

        }),
        updatePmfmeDocumentById:builder.mutation({
            query:({id,payload})=>({url:`/loan-application/document-detail/upload-document`,method:`POST`,body:payload })

        }),
        getAnalyticsApiData :builder.query({
            query: (payload) => ({ url: `/mis/api/v1/analytics`, method: 'POST', body: payload })

        }),

        getFeedbackFormById: builder.query({
            query: (pagination) => `/api/v1/banking/feedback?page=${pagination.page}&per_page=10`
        }),
        updateFeedbackData :builder.mutation({
            query: ({updatedId,payload}) => ({ url: `/api/v1/banking/feedback/${updatedId}`, method: 'PATCH', body: payload })

        }),
     
        uploadTakeActionsBanking: builder.mutation({
            query: ({id,formData}) => ({ url: `/api/v1/banking/update-application-status/${id}`, method: 'PATCH', body: formData })

        }),
        getCommissionApplicationListbyId :builder.query({
            query: (posp_id) => `/api/v1/banking/applications/posp/${posp_id}`,
          }),
          createCommission: builder.mutation({
            query: (payload) => ({
              url: `/api/v1/banking/create-commission`,
              method: `POST`,
              body: payload,
              headers: {
                "Content-Type": "application/json",
              },
            }),
          }),

    }),
   
})

export const { useGetBankingLeadsQuery,useGetBankingLeadsByIdQuery,useAddNewBankingLeadMutation,useUpdateBankingLeadByIdMutation,useGetBankingApplicationsQuery,
    useGetBankingApplicationsByIdQuery,useGetoccupationDropdownQuery,useUpdateConsentMutation,useAddBankingApplicationMutation,
    useGetBankingApplicationDetailsIdQuery,useCreateApplicantMutation,useGetCrmLeadsQuery,useGetCrmLeadsByIdQuery,useAddCrmLeadMutation,
    useUpdateCrmLeadByIdMutation,useUpdateLeadApplicationMutation,useGetBankingCrmDocumentsQuery,useGetBankingLoanApplicationQuery 
    ,useGetBankingLeadsListQuery ,useGetPmfmeDataQuery,useGetPmfmeDatabyIdQuery,usePmfmeAprrovalbyIdMutation,
     useUpdateApplicantBankingMutation,useUpdatePmfePersonalByIdMutation,useUpdatePmfeBusinessByIdMutation ,
     useUpdatePmfmeDocumentByIdMutation,useGetAnalyticsApiDataQuery,useGetFeedbackFormByIdQuery
     ,useUpdateFeedbackDataMutation,useUploadTakeActionsBankingMutation ,useGetCommissionApplicationListbyIdQuery ,useCreateCommissionMutation} = BankingApiSlice  